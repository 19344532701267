<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <v-layout wrap>
      <v-flex xs12 py-10 class="popregular" style="color: black; font-size: 40px">
        Wild India needs your help</v-flex>
    </v-layout> -->
    <v-layout wrap justify-center>
      <v-flex xs11 pt-13 pb-9 class="hidden-sm-and-down">
        <v-layout wrap justify-end>
          <v-flex xs8 sm8 md3 lg3 xl2 xxl2>
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#e27725" dark class="popregular medium" v-bind="attrs" v-on="on">
                  EMAIL SUBSCRIPTION
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2  popregular medium">
                  Subscribe Now
                </v-card-title>
                <v-card-text class="popregular">
                  <div id="mc_embed_signup">
                    <form action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                        <div id="mc_embed_signup_scroll"><h2>Subscribe to the WTI Newsletter</h2>
                            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                            <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-LNAME">Last Name </label><input type="text" name="LNAME" class=" text" id="mce-LNAME" value=""></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
                <div hidden=""><input type="hidden" name="tags" value="3953508"></div>
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display: none;"></div>
                            <div class="response" id="mce-success-response" style="display: none;"></div>
                        </div><div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_2c47e36b829020c293c520c3f_e29f2715de" tabindex="-1" value=""></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
                    </div>
                </form>
                </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>

          <v-flex xs4 md2 lg1 pl-6>
            <a href="#faq-section">
              <v-btn color="#e27725">
                <span class="popregular medium" style="color:white">
                  FAQ'S
                </span>
              </v-btn>
            </a>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-9 pt-10 class="hidden-sm-and-up">
        <v-layout wrap justify-end >
          <v-flex xs4 sm4>
            <a href="#donation-section">
              <v-btn color="#e27725">
                <span class="popregular" style="color:white">
                  Donation
                </span>
              </v-btn>
            </a>
          </v-flex>
          <v-flex xs5 sm5>
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#e27725" dark class="popregular" v-bind="attrs" v-on="on">
                  Subscribe
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2  popregular">
                  Subscribe Now
                </v-card-title>
                <v-card-text class="popregular">
                  <div id="mc_embed_signup">
                    <form action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                        <div id="mc_embed_signup_scroll"><h2>Subscribe to the WTI Newsletter</h2>
                            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                            <div class="mc-field-group"><label for="mce-FNAME">First Name <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-LNAME">Last Name </label><input type="text" name="LNAME" class=" text" id="mce-LNAME" value=""></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
                <div hidden=""><input type="hidden" name="tags" value="3953508"></div>
                        <div id="mce-responses" class="clear">
                            <div class="response" id="mce-error-response" style="display: none;"></div>
                            <div class="response" id="mce-success-response" style="display: none;"></div>
                        </div><div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_2c47e36b829020c293c520c3f_e29f2715de" tabindex="-1" value=""></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
                    </div>
                </form>
                </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>

          <v-flex xs3 sm3>
            <a href="#faq-section">
              <v-btn color="#e27725">
                <span class="popregular" style="color:white">
                  FAQ'S
                </span>
              </v-btn>
            </a>
          </v-flex>
         
        </v-layout>
      </v-flex>
      <v-flex xs12 md7>
        <v-layout wrap>
          <v-flex xs12 >
            <div>
              <iframe
              :height="
$vuetify.breakpoint.name == 'xs'
 ? '200px'
 : $vuetify.breakpoint.name == 'sm'
 ? '350px'
 : $vuetify.breakpoint.name == 'md'
 ? '300px'
 : $vuetify.breakpoint.name == 'xl'
 ? '400px'
 : $vuetify.breakpoint.name == 'lg'
 ? '350px'
 : '350px'
"
      width="100%"

       src="https://www.youtube.com/embed/gWB0FDDAKuw"
       frameborder="1"
       allowfullscreen
       ng-show="showvideo"
     ></iframe>
   </div>
          
          </v-flex>

          <v-flex xs12 px-2 pt-8 pb-6 pb-sm-4 pb-md-0 style="text-align:justify" class="popregular medium">
            At WTI, we offer a
            <span style="font-weight:bold;">

              second chance at life
            </span>
            to wild animals that are orphaned at a very
            young age. After losing parental care, it becomes nearly impossible for these young
            animals to survive on their own in the wild.


<br><br>
        
            <span>
              Our team of trained veterinarians and animal keepers step in to rescue these vulnerable
              individuals. We provide them with long-term foster care at specialized rescue centres,
              nurturing them back to health and ensuring their well-being. Our ultimate goal is to
              prepare them for a life of freedom in the wild –
              their true home.
            </span>
            <br><br>
            <span>
              Our joy knows no bounds when rehabilitated individuals successfully adapt to their natural environment and even give birth to their own offspring in the wild.
              <span style="text-decoration: underline;color:#3d673a;font-weight:bold; cursor: pointer;"
              @click="navigateToRehabitation"> Our Success Stories. </span>

            </span>
        


            <br><br>
        
            <span>
              This is an appeal where we seek your support in providing daily nutrition and medical
              care for these wild animals undergoing rehabilitation with us.
            </span>
            <br><br>
            <span>
              <span style="font-weight:bold;">
                Join us to be a part of a legacy
              </span>
              where together we&#39;ll witness these young ones grow into
              strong, free-roaming wildlings.
            </span>
        
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 md4 pl-0 pl-md-7 id="donation-section">
        <v-card elevation="3" v-if="firstCard" class="animate2">
          <v-layout wrap justify-center pa-3 pa-sm-0>



            <v-flex xs12>

              <v-layout wrap justify-center>
                <v-flex xs11 pa-4 style="background-color:#e27725 ;">
                  <span class="popregular" style="font-size: large;color: white;">Donate Now </span>

                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-center class="popregular">
                <v-flex xs12>
                  <v-layout pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN1">
                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap fill-height>
                          <v-flex align-self-center xs3 pt-6 pb-6>
                            <span>
                              {{ sign }}
                            </span>
                            <span style="font-size:20px">
                              {{ BTN1 }}
                            </span>
                          </v-flex>
                          <v-flex xs8 md8 pa-1 text-left align-self-center>
                            <span style="font-size: 13px;">
                              feeds an orphaned individual for a day

                            </span>
                          </v-flex>
                          <v-flex xs1 md1 style="background-color:#E27B25;">
                            <v-layout wrap justify-center fill-height>
                              <v-flex xs12 align-self-center>

                                <v-icon color="white">mdi-chevron-right</v-icon>

                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>
                  <v-layout pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN2">


                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap fill-height>
                          <v-flex align-self-center xs3 pt-6 pb-6>
                            <span>
                              {{ sign }}
                            </span>
                            <span style="font-size:20px">
                              {{ BTN2 }}
                            </span>
                          </v-flex>
                          <v-flex xs8 md8 pa-1 text-left align-self-center>
                            <span style="font-size: 13px;">
                              feeds an individual for an entire week

                            </span>
                          </v-flex>
                          <v-flex xs1 md1 style="background-color:#E27B25;">
                            <v-layout wrap justify-center fill-height>
                              <v-flex xs12 align-self-center>

                                <v-icon color="white">mdi-chevron-right</v-icon>

                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>
                  <v-layout pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN3">


                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap fill-height>
                          <v-flex align-self-center xs3 pt-6 pb-6>
                            <span>
                              {{ sign }}
                            </span>
                            <span style="font-size:20px">
                              {{ BTN3 }}
                            </span>
                          </v-flex>
                          <v-flex xs8 md8 pa-1 text-left align-self-center>
                            <span style="font-size: 13px;">
                              provides food concentrates and fodder to an individual for a month

                            </span>
                          </v-flex>
                          <v-flex xs1 md1 style="background-color:#E27B25;">
                            <v-layout wrap justify-center fill-height>
                              <v-flex xs12 align-self-center>

                                <v-icon color="white">mdi-chevron-right</v-icon>

                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>
                  <v-layout pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN4">
                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap fill-height>
                          <v-flex align-self-center xs3 pt-6 pb-6>
                            <span>
                              {{ sign }}
                            </span>
                            <span style="font-size:20px">
                              {{ BTN4 }}
                            </span>
                          </v-flex>
                          <v-flex xs8 md8 pa-1 text-left align-self-center>
                            <span style="font-size: 13px;">
                              is required to purchase veterinary supplies and maintain the facility for a month


                            </span>
                          </v-flex>
                          <v-flex xs1 md1 style="background-color:#E27B25;">
                            <v-layout wrap justify-center fill-height>
                              <v-flex xs12 align-self-center>

                                <v-icon color="white">mdi-chevron-right</v-icon>

                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>
                  <v-layout pt-5 wrap justify-center style="cursor: pointer;"
                    @click="(secondCard = true), (firstCard = false), login(), donation = BTN5">


                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap fill-height>
                          <v-flex align-self-center xs3>
                            <span>
                              {{ sign }}
                            </span>
                            <span style="font-size:20px">
                              {{ BTN5 }}
                            </span>
                          </v-flex>
                          <v-flex xs8 md8 pa-1 text-left align-self-center>
                            <span style="font-size: 13px;text-align:justify">
                              Provides complete nourishment, enrichment, and veterinary care to an individual for a
                              month
                            </span>
                          </v-flex>
                          <v-flex xs1 md1 style="background-color:#E27B25;">
                            <v-layout wrap justify-center fill-height>
                              <v-flex xs12 align-self-center>

                                <v-icon color="white">mdi-chevron-right</v-icon>

                              </v-flex>
                            </v-layout>

                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>
                  <v-layout pb-5 pt-5 wrap justify-center style="cursor: pointer;">
                    <v-flex xs12 sm6 md12 lg11 class="orange-border">
                      <v-card flat height="auto">
                        <v-layout wrap justify-end fill-height>
                          <v-flex align-self-center xs11 style="
      
      font-family: poppinsregular;
      font-size: 12px;
      color: #000;
    ">

                            <v-text-field placeholder="Any Other Amount" color="#E27B25" solo flat height="74px"
                              type="number" id="my_textbox" hide-details="true" v-model="donation"
                              class="center text-field-transparent">
                              <template v-slot:prepend-inner>
                                <v-layout justify-center pt-3>
                                  <v-flex xs12 class="align-self-center">
                                    <span style="
          padding-right: 2px;
          font-family: poppinsregular;
          font-size: 12px;
          color: #000;
        ">{{ Cname + sign }}</span>
                                  </v-flex>
                                </v-layout>
                              </template>
                            </v-text-field>
                          </v-flex>


                          <v-flex @click="(secondCard = true), (firstCard = false), login()" xs1
                            style="background-color:#E27B25;">
                            <v-layout wrap fill-height>
                              <v-flex xs12 align-self-center>
                                <v-icon color="white">mdi-chevron-right</v-icon>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>

                    </v-flex>





                  </v-layout>

                </v-flex>
                <!-- <v-flex xs12 sm6 md12 lg6 pt-4 pr-3>
  <v-text-field
                type="number"
                id="my_textbox"
                height="60px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
</v-flex>
<v-flex  xs12 sm6 md12  lg5 pt-4>
  <v-btn
                @click="(secondCard = true), (firstCard = false) , login()"
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
</v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <!-- <v-layout wrap pt-8 pa-8 class="popregular">
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN1"
                    outlined
                    block
                    height="60px"
                    color="#e27725"
                    style="font-size: 20px"
                    dark
                    >{{ sign }}{{ BTN1 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN2"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN2 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="donation = BTN3"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN3 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    id="my_button"
                    @click="toTextField()"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 16px; text-transform: none"
                    block
                    >Any other amount</v-btn
                  >
                </v-flex>
              </v-layout> -->
            </v-flex>
            <!-- <v-flex xs12 px-10>
              <v-text-field
                type="number"
                id="my_textbox"
                height="70px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-3>
                    <v-flex xs12 
                      
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right:2px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
            </v-flex> -->
            <!-- <v-flex xs12 sm6 py-8>
              <v-btn
                @click="(secondCard = true), (firstCard = false) , login() "
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex> -->
            <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
              <span>
                <v-icon small>mdi-lock</v-icon>
              </span>
              <span>Secure Donation</span>
            </v-flex>
          </v-layout>
        </v-card>

        <v-card elevation="3" v-if="secondCard">
          <v-form ref="file">
            <v-layout wrap justify-center pa-3 class="animate" id="div1">
              <v-flex xs12>
                <v-layout wrap style="background-color: #f2f2f2">
                  <v-flex xs1 align-self-center style="background-color: #f2f2f2">
                    <v-icon @click="firstCard = true, secondCard = false">mdi-chevron-left</v-icon>
                  </v-flex>
                  <v-flex xs10 py-3 style="
                      background-color: #f2f2f2;
                      margin-bottom: 1px solid grey;
                    ">
                    <span class="popregular ml">Add Your Information.</span>
                  </v-flex>
                  <v-flex xs1 py-3>
                    <v-spacer></v-spacer>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-6 text-center style="color: grey">
                <span class="popregular " style="font-size:20px">Who's giving today?</span>
                <br />

                <i>
                  <span class="popregular">We’ll never share this information with anyone</span></i>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field class="center2" type="number" ref="amount" color="#828282" placeholder="Amount"
                  label="Amount" :prepend-inner-icon="sign" outlined rounded :rules="[rules.required]"
                  v-model="donation"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="firstname" color="#828282" prepend-inner-icon="mdi-account" label="First Name"
                  rounded outlined class="center2" :rules="[rules.required]" v-model="firstname"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="lastname" color="#828282" prepend-inner-icon="mdi-account" label="Last Name" rounded
                  outlined class="center2" :rules="[rules.required]" v-model="lastname"></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field ref="email" color="#828282" prepend-inner-icon="mdi-email" outlined rounded
                  class="center2" label="Email" :rules="[rules.email]" v-model="email"></v-text-field>
              </v-flex>
              <template v-if="taxExemption == true">
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pencil" outlined rounded class="center2"
                    label="Address" :rules="[rules.required]" v-model="Newaddress"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                    label="City" :rules="[rules.required]" v-model="Newcity"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                    label="Pincode" :rules="[rules.required]" v-model="Newpincode"></v-text-field>
                </v-flex>
                <v-flex xs12 px-5>
                  <v-text-field color="#828282" prepend-inner-icon="mdi-pen" outlined rounded class="center2"
                    label="Pan Number" :rules="[rules.required]" v-model="Newpannumber"></v-text-field>
                </v-flex>
              </template>
              <v-flex xs12 px-5 pt-2>
                <v-checkbox v-model="taxExemption" hide-details="" class="LBL" :value="true"
                  label="I wish to claim tax exemption against this donation"></v-checkbox>
              </v-flex>

              <v-flex xs12 px-5>
                <v-checkbox v-model="isanonymous" hide-details="" class="LBL" :value="true"
                  label="Make my donation anonymous "></v-checkbox>
              </v-flex>

              <v-flex xs12 px-5>
                <v-checkbox v-model="subscribe" hide-details="" class="LBL" :value="true" label="
                Subscribe to our newsletter for inspiring stories from our
                projects"></v-checkbox>
              </v-flex>

              <v-flex xs12 sm6 py-8>
                <v-btn @click="validate()" height="60px" color="#e27725" dark style="
                    font-size: 20px;
                    text-transform: none;
                    font-family: poppinsregular;
                  " block>Donate Now
                </v-btn>
              </v-flex>

              <v-flex class="popregular" xs12 py-5 text-center style="background-color: #f2f2f2">
                <span>
                  <v-icon small>mdi-lock</v-icon>
                </span>
                <span>Secure Donation</span>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>


      <v-flex xs12 pt-16 id="faq-section">
        <v-layout wrap justify-center>
          <v-flex xs11 text-left pl-3>
            <span style="font-weight:bold;" class="popregular ml">Frequently Asked Questions </span>
          </v-flex>

          <v-flex xs11 text-left pt-2 pl-3>
            <v-layout wrap>

              <v-flex xs12 pt-3 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        What is wildlife rehabilitation?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        Wildlife Rehabilitation is the treatment and temporary care of distressed wildlife and the
                        release of fit individuals back to their appropriate habitat.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        Why do wild animals get orphaned at a young age?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        Orphaned young animals result from various causes, including nest falls in birds and maternal
                        loss in species like elephants, rhinos, and bears due to factors like poaching, conflict, or
                        natural causes.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        How Rehabilitation Supports Conservation?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        Wildlife rehabilitation in India gives a second chance at life to orphaned wild animals and aids
                        in increasing the population of species like elephants, rhinos, and bears, which is vital for
                        ecosystem health.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">

                        Can a rehabilitated animal survive in the wild?


                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        A rehabilitated animal can indeed survive in the wild. For successful rehabilitation and
                        release, an animal must be in good health, possess the necessary survival skills, and the
                        release site must offer suitable habitat and protection from threats. Avoiding habituation to
                        humans is essential.
                        <br>Post-release monitoring and support for some time may be needed for a successful transition
                        to the wild.

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        Why can’t we keep the rescued individuals in captivity?

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        At WTI, we firmly believe that wild animals belong in their natural habitats, not in captivity.
                        Even well-constructed captive environments cannot replace the freedom and complexity of the
                        wild, which is essential for their well-being.
                        <br>Captivity should be a last resort, reserved for cases where animals cannot be safely
                        released into the wild.

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        Rehabilitating wild animals must be fun?

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        No, it's not. Wildlife rehabilitation, while intriguing and rewarding, is physically and
                        mentally demanding, emotionally taxing, and requires strict adherence to safety standards,
                        regulations, and established criteria by professional rehabilitators.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        How does WTI treat and rehabilitate wild animals?

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        At WTI, we follow established protocols for long-term wildlife rehabilitation, developed in
                        consultation with experts. We strictly adhere to government rules, update protocols with the
                        latest research, and cover nursing, site selection, and post-release monitoring.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>

              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <div>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="popregular medium"
                          style="color: black;font-weight: bold;font-size: 16px;">
                          Who is rehabilitating wild animals at WTI?

                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                          We have a dedicated team of 10 veterinarians and 16 animal keepers working tirelessly to
                          rehabilitate and treat orphaned and injured wildlife.
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </template>
              </v-flex>

              <v-flex xs12 pt-5 style="text-align: justify;">
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="popregular medium"
                        style="color: black;font-weight: bold;font-size: 16px;">
                        How will WTI utilise my contribution?

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="popregular medium" style="color: black;font-size: 14px;">
                        WTI maintains strict financial accountability to ensure your donation directly supports wildlife
                        conservation. Your contribution funds rescue, treatment, care, and monitoring for various
                        species, helping safeguard wildlife.
                        <br> SUBSCRIBE to the WTI Newsletter for updates on our impact.

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-flex>
            </v-layout>

          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>


  </div>
</template>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<script>
  import axios from "axios";
  export default {
    data() {
      return {
        dialog: false,
        Newaddress: '',
        Newcity: "",
        Newpincode: "",
        Newpannumber: "",
        donation2: null,
        //inherited varibles from donation Card
        BTN1: 1000,
        BTN2: 5000,
        BTN3: 15000,
        BTN4: 25000,
        BTN5: 50000,
        sign: "₹",
        Cname: "INR",
        country: "India",
        amount: null,
        firstname: null,
        lastname: null,
        mobile: null,
        email: null,
        address: null,
        city: null,
        state: null,
        postalcode: null,
        panno: null,
        hearaboutus: "",
        comments: null,
        isanonymous: false,
        subscribe: false,
        taxExemption: false,
        symbol: "₹",
        regionssymbol: [],
        message: "",
        resource: null,
        regions: [],
        //end of inherited variables from donationCard
        rules: {
          required: (value) => !!value || "Required.",
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
        },
        donation: null,
        firstCard: true,
        secondCard: false,
        radio: null,
        showSnackBar: false,
        msg: "",
        ServerError: false,
        timeout: 3000,
        appLoading: false,
      };
    },
    watch: {
      country() {
        if (this.country != "India") {
          this.BTN1 = 10;
          this.BTN2 = 25;
          this.BTN3 = 50;
          this.BTN4 = 75;
          this.BTN5 = 100;

          this.sign = "$";
          this.Cname = "USD";
          this.donation = this.BTN1;
          //  console.log("true not india")
        } else {
          this.BTN1 = 10;
          this.BTN2 = 25;
          this.BTN3 = 50;
          this.BTN4 = 75;
          this.BTN5 = 100;
          this.sign = "₹";
          this.Cname = "INR";
          this.donation = this.BTN1;
          //  console.log("false india")
        }
      },
    },
    beforeMount() {
      axios({
        method: "GET",
        url: "/country/all",
      })
        .then((response) => {
          this.regions = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      // this.getData();
    },
    methods: {
      navigateToRehabitation() {
        this.$router.push("/success-stories");
      },
      login() {
        this.$gtag.event('login', { method: 'Google' })
      },
      toTextField() {
        this.donation = null;
        document.getElementById("my_button").onclick = function () {
          document.getElementById("my_textbox").focus();
        };
      },
      validate() {
        if (this.$refs.file.validate()) {
          var don = {};
          this.appLoading = true;
          don["country"] = this.country;
          don["amount"] = this.donation;
          don["firstname"] = this.firstname;
          don["lastname"] = this.lastname;
          // don["donationtype"] = this.$route.query.DonationName;
          don["email"] = this.email;
          don["isanonymous"] = this.isanonymous;
          don["subscribe"] = this.subscribe;
          don["address"] = this.Newaddress;
          don["city"] = this.Newcity;
          // don["pincode"] = this.Newpincode;
          // don["panNumber"] = this.Newpannumber;
          don["taxExemption"] = this.taxExemption;
          don["postalcode"] = this.Newpincode;
          don["panno"] = this.Newpannumber;
          // taxExemption
          axios({
            method: "post",
            url: "/payment/initiate",
            data: don,
          })
            .then((response) => {
              if (response.data.status) {
                localStorage.setItem("currencySymbol", this.symbol);
                this.appLoading = false;
                this.$router.push(
                  "/payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
                );
              } else {
                this.appLoading = false;
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
            })
            .catch(() => {
              this.appLoading = false;
              this.ServerError = true;
            });
        } else {
          this.showSnackBar = true;
          this.msg = "Please complete your form";
        }
      },
    },
  };
</script>
<style scoped>
  .input-group-1-2 .v-input__control {
    border-color: white;
  }

  .LBL>>>label {
    font-family: poppinsregular !important;
    font-size: 12px !important;

  }

  .v-text-field--outlined>>>fieldset {
    border-color: grey;
    border-radius: 5px;
  }

  .v-radio>>>label {
    font-size: 20px;
    font-family: poppinsregular;
    color: black;
  }

  .center>>>input {
    text-align: center;
    font-family: poppinsregular;
    font-size: 18px;
  }

  .center2>>>label {
    text-align: center;
    font-family: poppinsregular;
    font-size: 20px;
  }

  .center4 {
    text-align: center !important;
    font-family: poppinsregular !important;

  }

  .boldR>>>label {
    font-family: poppinsbold;
  }

  .animate {
    position: relative;
    animation: mymove 0.25s;
  }

  .animate2 {
    position: relative;
    animation: mymove2 0.25s;
  }

  #div2 {
    animation-timing-function: linear;
  }

  @keyframes mymove2 {
    from {
      left: -200px;
    }

    to {
      left: 0px;
    }
  }

  @keyframes mymove {
    from {
      right: -500px;
    }

    to {
      right: 0px;
    }
  }

  #div1 {
    animation-timing-function: linear;
  }

  ::v-deep .v-text-field__details::placeholder {
    font-size: 16px;
    /* Adjust the font size as needed */
  }

  .v-text-field--outlined>>>fieldset {
    box-shadow: none !important;
  }


  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear;
    /* Safari */
    animation: spin 2s linear;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .orange-border {
    border: 2px solid #E27B25;
    border-radius: 5px;
  }

  .v-checkbox>>>label {
    font-family: poppinsregular;
  }

  .text-field-transparent .v-input__slot {
    background: transparent !important;
  }
</style>